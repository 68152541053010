import React, { useState } from 'react';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import SanityImage from 'gatsby-plugin-sanity-image';
import Link from './link';
import { goodColors } from './colors';

export default function ProjectCard({ project, date, text }) {
  let formattedDate;

  if (date) {
    formattedDate = format(new Date(date), 'MMMM d, yyyy');
  }

  return (
    <div
      css={css`
        position: relative;
        filter: var(--dropShadow);

        @media (max-width: 600px) {
          img {
            width: 100%;
            height: 100%;
            aspect-ratio: 3 / 4;
            object-fit: cover;
          }
        }
      `}
    >
      {project.featuredImage && (
        <SanityImage
          {...project.featuredImage}
          width={1200}
          style={{
            display: 'block',
            width: '100%',
            height: 'auto',
          }}
        />
      )}
      <div
        css={css`
          position: absolute;
          top: 20px;
          left: 25px;
          max-width: 350px;
          background-color: ${goodColors[((project.slug?.current.length % goodColors.length) + goodColors.length) % goodColors.length]};
          padding: 20px;
          border: 3px solid var(--black);

          @media (max-width: 600px) {
            right: 25px;
          }
        `}
      >
        <div
          css={css`
            margin-bottom: 35px;
          `}
        >
          <span className="type--mono-heading">{!date && project.title}{date && `${project.title} — ${formattedDate}`}: </span>
          <span className="type--body">
            {
              !text && project._rawOverview
                ? (() => {
                    const text = project._rawOverview
                      .map(el => el.children)
                      .flat()
                      .map(el => el?.text)
                      .join(' ');
                    const sentences = text.split('.');
                    return sentences.slice(0, 1).filter(s => s.trim().length > 0).join('. ') + '.';
                  })()
                : null
            }
            {
              text && text
                ? (() => {
                    const textNew = text
                      .map(el => el.children)
                      .flat()
                      .map(el => el?.text)
                      .join(' ');
                    const sentences = textNew.split('.');
                    return sentences.slice(0, 1).filter(s => s.trim().length > 0).join('. ') + '.';
                  })()
                : null
            }
          </span>
        </div>
        <Link to={`/project/${project.slug?.current}`}>View Project</Link> →
      </div>
    </div>
  );
}
