import React, { useState } from 'react';
import { css } from '@emotion/react';
import useEmblaCarousel from 'embla-carousel-react';
import SanityImage from 'gatsby-plugin-sanity-image';
import Link from './link';
import BlockContent from './block-content';
import { goodColors } from './colors';
import InlineGallery from './inline-gallery';

export default function GalleryCard({ gallery }) {
  return (
    <div
      css={css`
        position: relative;
        background-color: var(--white);
        filter: var(--dropShadow);
        padding: 20px 25px;
        border: 3px solid var(--black);

        > div > div > div:nth-of-type(2) {
          position: relative;
          padding: 0;

          > div:nth-of-type(1), > div:nth-of-type(3) {
            opacity: 0;
          }

          > div:nth-of-type(2)
            position: relative;
            top: -12px;

            @media (max-width: 550px) {
              top: 0;
            }
          }
        }
      `}
    >
      {gallery.images.length > 0 && <InlineGallery
        items={gallery.images.map((item) => {
          return {
            assetType: item._type === 'file' ? 'video' : item._type,
            image: item,
          };
        })}
        auto
        contain
        small
      />}
      <div
        css={css`
          margin-top: 40px;
        `}
      >
        <BlockContent content={gallery._rawText} />
      </div>
    </div>
  );
}
