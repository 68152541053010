import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import ProjectCard from '../components/project-card';
import ArticleCard from '../components/article-card';
import GalleryCard from '../components/gallery-card';

export default function HomeContent({ content }) {
  const [offsets, setOffsets] = useState([]);
  const [articleOffsets, setArticleOffsets] = useState([]);
  const [projectOffsets, setProjectOffsets] = useState([]);
  const contentEl = useRef([]);

  useEffect(() => {
    if (content?.length > 0) {
      setOffsets(content.map(() => Math.ceil(Math.random() * 5)));
      setArticleOffsets(content.map(() => Math.floor(Math.random() * 2)));
      setProjectOffsets(content.map(() => Math.floor(Math.random() * 2)));
    }
  }, [content]);

  function getDistanceFromTop(element) {
    let distance = 0;
    while (element) {
      distance += element.offsetTop;
      element = element.offsetParent;
    }
    return distance;
  }

  const handleScroll = () => {
    if (!contentEl.current) return;

    const r = contentEl.current.getBoundingClientRect();
    const h = window.innerHeight;

    if (r.top > h || r.bottom < 0) return;

    const offset = window.scrollY - getDistanceFromTop(contentEl.current) + h;
    const ratios = [
      0.01,
      0.04,
      0.02,
      0.01,
      0.03,
    ];

    Array.from(contentEl.current.children).forEach((child, i) => {
      child.style.transform = `translateY(${-offset * ratios[i % ratios.length]}px)`;
    });
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      ref={contentEl}
      css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: var(--gutter);
        margin-top: calc(2 * var(--gutter));
        margin-bottom: var(--margin20);

        @media (max-width: 1200px) {
          display: block;
          margin-bottom: 0;

          > div {
            top: 0;
            margin-bottom: var(--gutter) !important;
            transform: translateY(0) !important;
          }
        }

        @media (min-width: 1750px) {
          grid-template-columns: repeat(18, 1fr);
        }

        @media (min-width: 1800px) {
          max-width: 1700px;
          margin: 0 auto;
        }

        > div {
          margin-bottom: calc(var(--gutter) * 4);
          will-change: transform;
        }
      `}
    >
      {content?.length > 0 && content?.map((item, i) => {
        const offset = offsets[i] !== undefined ? offsets[i] : 0;
        const articleOffset = articleOffsets[i] !== undefined ? articleOffsets[i] : 0;
        const projectOffset = projectOffsets[i] !== undefined ? projectOffsets[i] : 0;
        return (
          <React.Fragment key={item._key || i}>
            {item._type === 'project' && (
              <div
                css={css`
                  position: relative;
                  grid-column: ${offset} / span 8;

                  @media (min-width: 1750px) {
                    grid-column-start: auto;
                  }
                `}
              >
                <ProjectCard project={item} />
              </div>
            )}
            {item._type === 'projectCaseStudy' && (
              <div
                css={css`
                  position: relative;
                  grid-column: ${offset} / span 8;

                  @media (min-width: 1750px) {
                    grid-column-start: auto;
                  }
                `}
              >
                <ProjectCard project={item.project} date={item.date} text={item._rawText} />
              </div>
            )}
            {item._type === 'article' && (
              <div
                css={css`
                  position: relative;
                  top: calc(${articleOffset === 0 ? -1 : 2} * var(--gutter));
                  grid-column-start: span 4;
                `}
              >
                <ArticleCard article={item} />
              </div>
            )}
            {item._type === 'galleryWithText' && (
              <div
                css={css`
                  grid-column-start: span 5;
                  grid-column: ${offset} / span 5;
                `}
              >
                <GalleryCard gallery={item} />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
