import React, { useState } from 'react';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import Link from './link';

export default function ArticleCard({ article }) {
  const date = format(new Date(article.datetime), 'MMMM d, yyyy');

  return (
    <div
      css={css`
        position: relative;
        filter: var(--dropShadow);
        background-color: var(--white);
        padding: 20px 25px;
        border: 3px solid var(--black);
      `}
    >
      <div
        css={css`
          margin-bottom: 35px;
        `}
      >
        <div
          className="type--mono-heading"
          css={css`
            margin-bottom: 20px;
          `}
        >
          News – {date}<br />
          {article.title}
        </div>
        <div className="type--paragraph">
          {
            '“' + article.content
            .filter(block => block._rawText)
            .map(block => block._rawText.map(textObj =>
              textObj.children.map(child => child.text).join('')
            ).join(' '))
            .join(' ')
            .slice(0, 100)
            .trim()
            + '...”'
          }
        </div>
      </div>
      <Link to={`/article/${article.slug?.current}`}>Read Article</Link> →
    </div>
  );
}
