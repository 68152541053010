import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import Video from './video';

const fadeGalleryStyles = css`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .fade-item {
    position: absolute;
    inset: 0 0 0 0;
    opacity: 0;
    transition: opacity 1s var(--curve);
    z-index: 0;

    video, img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .fade-item.current {
    opacity: 1;
    z-index: 2;
  }

  .fade-item.previous {
    opacity: 0;
    z-index: 1;
    transition-delay: 1s;
  }
`;

export default function FadeGallery({ className, items, interval = 7000 }) {
  const [current, setCurrent] = useState(0);
  const [previous, setPrevious] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setPrevious(current);
      setCurrent((prev) => (prev + 1) % items.length);
    }, interval);

    return () => clearInterval(timer);
  }, [current, items.length, interval]);

  return (
    <div className={className} css={fadeGalleryStyles}>
      {items.map((item, index) => {
        const isCurrent = index === current;
        const isPrevious = index === previous;

        let fadeItemClass = 'fade-item';
        if (isCurrent) fadeItemClass += ' current';
        else if (isPrevious) fadeItemClass += ' previous';

        return (
          <div key={index} className={fadeItemClass}>
            {item.video ? (
              <Video
                src={item.video.asset.url}
                autoPlay
                muted
                loop
              />
            ) : (
              <SanityImage
                {...item.image}
                width={1000}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
